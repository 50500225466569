<script >
export default {
  name: 'AssetManagement'
}
</script>

<template>
<router-view></router-view>
</template>

<style scoped lang="less">

</style>